:root {
  --color-background: #F7F7F7;
  --color-font-primary: #0B0C0D;
  --color-font-secondary: #707070;
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--color-background);
  color: var(--color-font-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.App-headerSlogan {
  font-family: "Quicksand", sans-serif;
  color: var(--color-font-secondary);
  font-weight: 300;
  font-size: 15pt;
  padding: 16px;
  margin-bottom: 96px;
}

.App-headerLogo {
  height: 100px;
  width: 100px;
  pointer-events: none;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
